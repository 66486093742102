import React from 'react';

function ProgressBar(props) {
  const { percentage } = props;
  return (
    <div className="bg-gray-100 h-fit rounded-2xl">
      <div
        className={`h-2 rounded-3xl bg-gradient-to-br text-transparent from-primaryLeft to-primaryRight transition-all duration-1000`}
        style={{
          width: `${percentage}%`,
          maxWidth: '100%',
        }}
      ></div>
    </div>
  );
}

export default ProgressBar;
