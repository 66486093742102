export const API_URL = process.env.REACT_APP_API_URL;
// "https://monkapi.azure-api.net";
// EMPLOYEE
// COMPENSATION
// export const API_URL = `https://dev2-api.talentmonk.com`;
//
export const API_EMP = 'emp';
export const API_EMPLOYEE = 'employee';
export const MEDIA_LINK = process.env.REACT_APP_MEDIA_LINK;
// RNR-V2
export const MONK_V3 = 'monk-v3';
// RNR-V2
export const API_RNR_V2 = 'rnr-v3';
// HIRING-V3
export const HIRING_V3 = 'hiring-v3';
// RBAC-V3
export const API_RBAC_V2 = 'rbac-v2';
export const API_RBAC_V3 = 'rbac-v3';
// ${CONSTANT.API_SURFACE_V2}
export const API_SURFACE_V2 = 'surface-v3';
// WORKFLOW-V2
export const API_WORKFLOW_V2 = 'workflow-v3';
// EMOTION
export const API_EMOTION = 'emotion';
// POST
export const API_POST = 'post';
// celebration
export const API_CELEBRATE = 'celebrate';
// FEED
export const API_FEED = 'feed';
// FLAG
export const API_FLAG = 'flag';
// EVENT
export const API_EVENT = 'event';
// BENEFITS
export const API_BENEFITS_V2 = 'benefit-v3';

export const API_RANGE_BUILDER_V3 = 'range-builder-v3';

// SURVEY
export const API_SURVEY = 'survey';
// SOCIAL
export const API_SOCIAL = 'social';
// TRENDING
export const API_TRENDING = 'trending';
// ANNOUNCEMENT
export const API_ANNOUNCEMENT = 'announce';
// PROGRAM
export const API_PROGRAM = 'program';
// AWARD
export const API_AWARD = 'award';
// NOMINATE
export const API_NOMINATE = 'nominate';
// FORM
export const API_FORM = 'form';
// COMMUNITY
export const API_COMMUNITY = 'community';
// RECOGNITION
export const API_RECOGNITION = 'recognition';
// BADGE
export const API_BADGE = 'badge';
// CARD
export const API_CARD = 'card';
// WORKFLOW
export const API_WORKFLOW = 'workflow';
// WALLET
export const API_WALLET = 'wallet';
// BUDGET
export const API_BUDGET = 'budget';
// Utility V2
export const API_UTILITY_V2 = 'utility-v3';
// Utility V2
export const API_UTILITY_V3 = 'utility-v3';
// CERTIFICATE
export const API_CERTIFICATE = 'certificate';
// REPORT
export const MASTER = 'master';
// MASTER
export const API_REPORT = 'report';
// DASHBOARD
export const API_DASHBOARD = 'dashboard';
export const API_DASHBOARD_V3 = 'dashboard-v3';
// AUTH
export const API_LOGIN_URL = `${API_URL}/login`;
export const API_SEND_RESET_PASSWORD_EMAIL_URL = `${API_URL}/users/reset-password`;
export const API_SEND_CHANGE_PASSWORD = `${API_URL}/users/define-password`;
export const API_DEFINE_PASSWORD_URL = `${API_URL}/users/define-password`;
// MEDIA
export const API_MEDIA_URL = `${API_URL}/media-upload`;
// SETTINGS
export const API_CONFIG_CMS_URL = `${API_URL}/config_cms`;
// USER
export const API_USER_URL = `${API_URL}/users`;
// ORG
export const API_ORG_WITH_SUBDOMAIN = 'get-org-details-by-subdomain';
// Surface
export const GET_MY_FEED = 'get-my-feed';
// SIDENAV
export const GET_SIDENAV = `${API_SURFACE_V2}/get-side-nav`;
// PROFILE

export const RECOG_API = `${API_URL}/rnr-v3`;
export const COMPENSATION_V2 = 'compensation-v3';
export const COMPENSATION_V3 = 'compensation-v3';
// COMPENSATION
export const API_COMPENSATION_V2 = COMPENSATION_V2;

export const COMPENSATION = `${API_URL}/${COMPENSATION_V2}/compensation-master`;

export const COMPENSATION_PLANS_URL = `${API_URL}/${COMPENSATION_V2}/manage-plans`;

export const COMPENSATION_CURRENCY = `${API_URL}/${COMPENSATION_V2}/currency`;

export const COMPENSATION_BUDGET = `${API_URL}/${COMPENSATION_V2}/compensation-plan/budget`;

export const COMPENSATION_PLAN = `${API_URL}/${COMPENSATION_V2}/compensation-plan`;

export const COMPENSATION_2A = `${API_URL}/${COMPENSATION_V2}/compensation-2a`;

export const COMPENSATION_LETTER = `${API_URL}/${COMPENSATION_V2}/letter`;

export const TASK_URL = `${API_URL}/`;

export const DEBOUNCE_SEARCH_TIME = 500;

// used in letter template file to debounce the API request
export const TABLE_SAVE_NETWORK_REQUEST_THROTTLE_TIME = 500;

export const DEFAULT_IMAGE_PATH = '/images/errorImage.png';

export const DEFAULT_AVATAR_PATH = '/images/userAvatar.png';

export const TAILWIND_STYLES_FETCH =
  'https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css';
